.sideNavList {
    margin-top: 18px !important;
}
.logoImg {
    margin-right: 10px;
}
.collapIcon{
    margin-right: 10px;
    margin-bottom: -3px;
}
.collapseText{
    color: white;
    font-size: 16px;
}
.active svg path {
    fill: white !important;
}
.collapseText.false{
    display: none;
}
.collapIcon.false{
    transform: rotate(180deg);
}