.breadHome{
    font-family: 'Nunito Sans';
font-style: normal;

font-size: 16px;
line-height: 22px;

}
.crumbEle{
    margin-left: 12px;
    margin-right: 12px;
}
.svgSection{
    display: flex;
    flex-direction: column;
    width: 18px;
    height: 18px;
    align-items: center;
}
.homeCrumb{
    display: flex;
}
.MuiBreadcrumbs-separator{
    margin-top: 1px;
}