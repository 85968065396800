.clifdashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.dbNftCard{
    background-color: #080808 !important;
    height: 100%;
    border-radius: unset !important;
}
.tokenOverview{
    background-color: #080808 !important;
}
.openTokenBtn{
    width: 90%;
    height: 56px;
background: #9B111E;
border-radius: 8px;
border: none;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-family: 'Nunito Sans';
font-style: normal;
font-size: 20px;
line-height: 27px;
margin-left: auto;
margin-right: auto;
svg{
    margin-right: 6px;
}
}

@media screen and (max-width:1280px) {
    .NFTMobile{
        display: block;
        margin-top: 25px !important;
    }
    .NFTDesktop{
        display: none;
    }
}
@media screen and (min-width:1280px) {
    .NFTMobile{
        display: none;
    }
    .NFTDesktop{
        display: block;
    }
}

.clifdashboardSection{
    width: calc(100% + 60px) !important;
}

.nftTokenImages{
    max-height: 300px;
    overflow-y: scroll;
}
.nftTokenImages::-webkit-scrollbar-thumb{
    background: white;
}
.nftTokenImages::-webkit-scrollbar{
    width: 2px;
}
