
.claimForm{
    width: 560px;
    height: 640px;
    background: #080808;
border-radius: 8px;
margin: 0 auto;
margin-top: 20px;
padding: 10px;
}
.claimIcon {
    margin: 0 auto;
    text-align: center;
    margin-left: 10px;
}
.claimHeading {
    text-align: center;
    margin-top: 10px;
    color: white;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 10px;
}
.claimBalance{
    background: #141414;
border-radius: 8px;
height: 64px;
margin: 0 auto;
display: flex;
align-items: center;
color: white;
width: 90%;
margin-bottom: 20px;
svg{
    margin-left: 10px;
}
span{
    font-family: 'Nunito Sans';
font-style: normal;
margin-left: 15px;
font-size: 20px;
line-height: 27px;
color: #7B818C;
}
}
.claimAmtInpSec{
    display: flex;
    border: 1px solid #1F1F1F;
    padding: 5px;
    width: 90%;
    height: 64px;
    box-sizing: border-box;
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
    .claimAmtInp{
        background: inherit;
        color: white;
        border: none;
        width: 75%;
        height: 60px;
        font-size: 20px;
        padding: 10px;
    }
    
    button{
        width: 90px;
        height: 36px;
        background: #191919;
        border-radius: 8px;
        color: white;
        border: none;
    }
}
.claimBtn{
    width: 90%;
height: 56px;
background: #9B111E;
border-radius: 8px;
text-align: center;
border: none;
color: white;
font-size: 20px;
line-height: 27px;
margin: 0 auto;
margin-top: 10px;
}
.claimBtnSec {
    display: flex;
}
button {
    cursor: pointer;
}
button:disabled{
    opacity: 0.4;
    cursor:not-allowed
}