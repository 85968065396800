.stakesSec{
    background-color:#080808 !important
}
.clifBalance{
    background: #141414;
border-radius: 8px;
height: 64px;
margin: 0 auto;
display: flex;
align-items: center;
color: white;
width: 90%;

svg{
    margin-left: 10px;
}
span{
    font-family: 'Nunito Sans';
font-style: normal;
margin-left: 10px;
font-size: 20px;
line-height: 27px;
color: #7B818C;
}
}
.cliffAmtInpSec{
    display: flex;
    border: 1px solid #1F1F1F;
    padding: 5px;
    width: 90%;
    height: 64px;
    box-sizing: border-box;
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
    .cliffAmtInp{
        background: inherit;
        color: white;
        border: none;
        width: 75%;
        height: 60px;
        padding: 10px;
        font-size: 20px;
    }
    
    button{
        width: 90px;
        height: 36px;
        background: #191919;
        border-radius: 8px;
        color: white;
        border: none;
    }
}
.clifUnBalance{
    background: #141414;
border-radius: 8px;
height: 64px;
margin: 0 auto;
display: flex;
align-items: center;
color: white;
width: 46%;
margin-left: 20px;
margin-right: 20px;
svg{
    margin-left: 10px;
}
span{
    font-family: 'Nunito Sans';
font-style: normal;
margin-left: 10px;
font-size: 20px;
line-height: 27px;
color: #7B818C;
}
}
.cliffUnAmtInpSec{
    display: flex;
    border: 1px solid #1F1F1F;
    padding: 5px;
    width: 45%;
    height: 64px;
    
    box-sizing: border-box;
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
        margin-right: 20px;
    .cliffAmtInp{
        background: inherit;
        color: white;
        border: none;
        width: 75%;
        height: 60px;
        font-size: 20px;
        padding: 10px
    }
    
    button{
        width: 90px;
        height: 36px;
        background: #191919;
        border-radius: 8px;
        color: white;
        border: none;
    }
}
.approveBtn{
    width: 48.5%;
height: 56px;
border: none;
background: #9B111E;
box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
border-radius: 8px;
color: white;
font-size: 16px;
}
.stakeBtn{
    width: 48.5%;
height: 56px;
background: #141414;
border-radius: 8px;
border: none;
font-size: 16px;
color: #7B818C;
}
.unstakeBtn{
    width: 20%;
    border: none;
color: white;
height: 56px;
margin-right: 20px;

background: #191919;
border-radius: 8px;
}
button {
    cursor: pointer;
}
button:disabled{
    opacity: 0.4;
    cursor:not-allowedl
}
input:focus{
    outline: none !important;
}
form:focus{
    border: 1px solid white;
}
.stakeCard{
    margin: 0 auto !important;
}
.rewardCard{
    margin: 0 auto !important;
}