.DbIconBox{
    width: 45px;
    height: 45px;
    margin-left: 15px;
    background: #191919;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DbIcon2Box{
    width: 102px;
    height: 45px;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex: 1 0;
    flex-direction: row-reverse;
}

.statCard{
    background: #080808 !important;
    background-color: #080808 !important;
}
.statPercentLabel{
    color: #7B818C !important;
}