.buyCliff{
    width: 180px;
    height: 56px;
    margin-top: auto;
    opacity: 1;
    background: transparent;
    color: #fff;
    border-radius: none;
    box-shadow: none;
    background: rgba(155, 17, 30, 0.1);
    border: 2px solid #9B111E;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
}
.connectWltBtn {
    width: 200px;
    height: 56px;
    background: #9B111E;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: unset;
    cursor: pointer;
    svg {
        // margin-right: 10px;
        margin-left: 12px;
    }
    span {
        width: 142px;
        height: 23.62px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-size: 18px;
        line-height: 27px;
        color: #FFFDF5;
        margin-bottom: 3px;
    }
}
header{
    
    margin-left: -9px;
}