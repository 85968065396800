.dayBtn {
    color: #7B818C;
    width: 82px;
    height: 36px;
    background: #191919;
    border-radius: 8px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    border: unset;
    margin-right: 10px;
    cursor: pointer;
}
.dayBtn.active {
    color: #fff;
    background-color: #9B111E;
}